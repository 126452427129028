import { lazy, Suspense } from "react";

import { EnvProvider } from "./components/envProvider/EnvProvider";
import { EnvConfig } from "./components/envProvider/EnvProvider.types";
import MfeLoading from "./components/mfeLoading/MfeLoading";

const ComboDetailsDrawerWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.ComboDetailsDrawerWrapper,
  })),
);

const OptimizelyWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.OptimizelyWrapper,
  })),
);

const AnalyticsWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.AnalyticsWrapper,
  })),
);

const RouterWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.RouterWrapper,
  })),
);

const UserConfigWrapper = lazy(() =>
  import("@bees-grow-shared/business-components").then((module) => ({
    default: module.UserConfigWrapper,
  })),
);

const ThemeProvider = lazy(() =>
  import("@bees-grow-shared/theme").then((module) => ({
    default: module.ThemeProvider,
  })),
);

const IntlProvider = lazy(() =>
  import("./i18n").then((module) => ({ default: module.IntlProvider })),
);

const CheckoutPage = lazy(() => import("./pages/checkout/CheckoutPage"));

export default function App(props: Readonly<EnvConfig>) {
  return (
    <EnvProvider env={props}>
      <Suspense fallback={<MfeLoading />}>
        <ThemeProvider>
          <UserConfigWrapper loadingComponent={<MfeLoading />}>
            <OptimizelyWrapper
              sdkKey={props.OPTIMIZELY_KEY}
              loadingComponent={<MfeLoading />}
            >
              <AnalyticsWrapper segmentKey={props.SEGMENT_KEY}>
                <IntlProvider>
                  <RouterWrapper>
                    <ComboDetailsDrawerWrapper>
                      <CheckoutPage />
                    </ComboDetailsDrawerWrapper>
                  </RouterWrapper>
                </IntlProvider>
              </AnalyticsWrapper>
            </OptimizelyWrapper>
          </UserConfigWrapper>
        </ThemeProvider>
      </Suspense>
    </EnvProvider>
  );
}
